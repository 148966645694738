.degradedPerformance {
  color: theme("colors.degradedPerformance.content.secondary");
  background-color: theme("colors.degradedPerformance.surface.primary");

  :global(.dark) & {
    color: theme("colors.degradedPerformance.dark.content.secondary");
    background-color: theme("colors.degradedPerformance.dark.surface.primary");
  }

  &.highlighted, &:hover {
    border-color: theme("colors.degradedPerformance.content.primary");
  }
}

.partialOutage {
  color: theme("colors.partialOutage.content.secondary");
  background-color: theme("colors.partialOutage.surface.primary");

  :global(.dark) & {
    color: theme("colors.partialOutage.dark.content.secondary");
    background-color: theme("colors.partialOutage.dark.surface.primary");
  }

  &.highlighted, &:hover {
    border-color: theme("colors.partialOutage.content.primary");
  }
}

.fullOutage {
  color: theme("colors.fullOutage.content.secondary");
  background-color: theme("colors.fullOutage.surface.primary");

  :global(.dark) & {
    color: theme("colors.fullOutage.dark.content.secondary");
    background-color: theme("colors.fullOutage.dark.surface.primary");
  }

  &.highlighted, &:hover {
    border-color: theme("colors.fullOutage.content.primary");
  }
}

.underMaintenance {
  color: theme("colors.maintenance.content.secondary");
  background-color: theme("colors.maintenance.surface.primary");

  :global(.dark) & {
    color: theme("colors.maintenance.dark.content.secondary");
    background-color: theme("colors.maintenance.dark.surface.primary");
  }

  &.highlighted, &:hover {
    border-color: theme("colors.maintenance.content.primary");
  }
}