// Calendar container
.container {
  background-color: theme("colors.surface.secondary");
  :global(.dark) & {
    background-color: theme("colors.slate.800");
  }

  @apply grid;
  @apply grid-cols-7;
  @apply gap-px;
  @apply text-xs;
  @apply font-medium;
  border-radius: 7px;
}

// Today highlight
.today {
  // The grid is provided by a 1px gap around each day, which shows the
  // `.container` background underneath. For today, we want to undo that gap, so
  // that our border replaces it.
  margin: -1px;
  @apply border;
  @apply border-slate-200;
  @apply font-semibold;

  :global(.dark) & {
    @apply border-slate-700;
  }
}

// Status-based day styles

// This style renders a grey border, however it is not shown unless
// a border width is also applied. This is done conditionally in the
// React code.
.operational {
  @apply text-content-tertiary;
  @apply border-stroke/50;

  &:hover {
    @apply text-slate-600;
    @apply border-stroke;
  }

  :global(.dark) & {
    @apply text-slate-500;
    @apply border-slate-500;

    &:hover {
      @apply text-content-tertiary;
      @apply border-slate-300;
    }
  }
}

.underMaintenance {
  @apply border-[1.5px];

  color: theme("colors.maintenance.content.primary");
  border-color: theme("colors.maintenance.content.primary");

  &:hover {
    border-color: theme("colors.maintenance.content.primaryHover");
    color: theme("colors.maintenance.content.primaryHover");
  }

  :global(.dark) & {
    color: theme("colors.maintenance.dark.content.primary");
    border-color: theme("colors.maintenance.dark.content.primary");

    &:hover {
      color: theme("colors.maintenance.dark.content.primaryHover");
      border-color: theme("colors.maintenance.dark.content.primaryHover");
    }
  }
}

.degradedPerformance {
  @apply border-[1.5px];

  color: theme("colors.degradedPerformance.content.primary");
  border-color: theme("colors.degradedPerformance.content.primary");

  &:hover {
    border-color: theme("colors.degradedPerformance.content.primaryHover");
    color: theme("colors.degradedPerformance.content.primaryHover");
  }

  :global(.dark) & {
    color: theme("colors.degradedPerformance.dark.content.primary");
    border-color: theme("colors.degradedPerformance.dark.content.primary");

    &:hover {
      color: theme("colors.degradedPerformance.dark.content.primaryHover");
      border-color: theme("colors.degradedPerformance.dark.content.primaryHover");
    }
  }
}

.partialOutage {
  @apply border-[1.5px];

  color: theme("colors.partialOutage.content.primary");
  border-color: theme("colors.partialOutage.content.primary");

  &:hover {
    border-color: theme("colors.partialOutage.content.primaryHover");
    color: theme("colors.partialOutage.content.primaryHover");
  }

  :global(.dark) & {
    color: theme("colors.partialOutage.dark.content.primary");
    border-color: theme("colors.partialOutage.dark.content.primary");

    &:hover {
      color: theme("colors.partialOutage.dark.content.primaryHover");
      border-color: theme("colors.partialOutage.dark.content.primaryHover");
    }
  }
}

.fullOutage {
  @apply border-[1.5px];

  color: theme("colors.fullOutage.content.primary");
  border-color: theme("colors.fullOutage.content.primary");

  &:hover {
    border-color: theme("colors.fullOutage.content.primaryHover");
    color: theme("colors.fullOutage.content.primaryHover");
  }

  :global(.dark) & {
    color: theme("colors.fullOutage.dark.content.primary");
    border-color: theme("colors.fullOutage.dark.content.primary");

    &:hover {
      color: theme("colors.fullOutage.dark.content.primaryHover");
      border-color: theme("colors.fullOutage.dark.content.primaryHover");
    }
  }
}
