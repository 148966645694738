.default {
  // This background acts as a border
  @apply bg-slate-100;

  :global(.dark) & {
    @apply bg-slate-700;
  }

  // No background - leave it transparent

  // Add a border to the bottom of the header
  .header {
    border-bottom: 1px solid theme("colors.slate.50");
  }
  :global(.dark) & {
    .header {
      border-bottom: 1px solid theme("colors.slate.800");
    }
  }
}

.operational {
  // This background acts as a border
  background: theme("colors.operational.content.primary");

  :global(.dark) & {
    background: theme("colors.operational.dark.content.primary");
  }

  // And the real background sits inside
  .header {
    background: theme("colors.operational.surface.primary");
  }

  :global(.dark) & {
    .header {
      background: theme("colors.operational.dark.surface.primary");
    }
  }
}

.degradedPerformance {
  // This background acts as a border
  background: theme("colors.degradedPerformance.content.primary");

  :global(.dark) & {
    background: theme("colors.degradedPerformance.dark.content.primary");
  }

  // And the real background sits inside
  .header {
    background: theme("colors.degradedPerformance.surface.primary");
  }

  :global(.dark) & {
    .header {
      background: theme("colors.degradedPerformance.dark.surface.primary");
    }
  }
}

.partialOutage {
  // This background acts as a border
  background: theme("colors.partialOutage.content.primary");

  :global(.dark) & {
    background: theme("colors.partialOutage.dark.content.primary");
  }

  // And the real background sits inside
  .header {
    background: theme("colors.partialOutage.surface.primary");
  }

  :global(.dark) & {
    .header {
      background: theme("colors.partialOutage.dark.surface.primary");
    }
  }
}

.fullOutage {
  // This background acts as a border
  background: theme("colors.fullOutage.content.primary");

  :global(.dark) & {
    background: theme("colors.fullOutage.dark.content.primary");
  }

  // And the real background sits inside
  .header {
    background: theme("colors.fullOutage.surface.primary");
  }

  :global(.dark) & {
    .header {
      background: theme("colors.fullOutage.dark.surface.primary");
    }
  }
}

.underMaintenance {
  // This background acts as a border
  background: theme("colors.maintenance.content.primary");

  :global(.dark) & {
    background: theme("colors.maintenance.dark.content.primary");
  }

  // And the real background sits inside
  .header {
    background: theme("colors.maintenance.surface.primary");
  }
  :global(.dark) & {
    .header {
      background: theme("colors.maintenance.dark.surface.primary");
    }
  }
}