@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Define some colours differently in light and dark modes */
  :root {
    --color-default: theme("colors.slate.50");
    --color-modal-bg: white;
    --color-cal-empty: rgba(255, 255, 255, 0.8);
    --color-icon-operational: #24c19a;
    --color-icon-degraded: #fbbf24;
    --color-icon-partial-outage: #f5785c;
    --color-icon-full-outage: #f87171;
    --color-icon-maintenance: #3c82f6;

    // This produces crispy text that's slightly less heavy
    @apply antialiased;
  }

  .dark {
    --color-default: theme("colors.slate.800");
    --color-modal-bg: theme("colors.slate.900");
    --color-cal-empty: rgba(22, 22, 24, 0.8);
    --color-icon-operational: #1fa382;
    --color-icon-degraded: #f59e0b;
    --color-icon-partial-outage: #f25533;
    --color-icon-full-outage: #ef4444;
    --color-icon-maintenance: #2463eb;
  }
}

@layer utilities {
  /* Global */
  $bg-global: #161618;
  .bg-global {
    background: $bg-global;
  }

  /* AI gradient */
  $bg-ai-rainbow: linear-gradient(
    92deg,
    #dadaf1 -1.6%,
    #e9e2ee 25.29%,
    #f1e1df 44.5%,
    #efe4d2 53.83%,
    #cfe3e8 70.84%,
    #c1b7df 103.77%
  );

  .bg-ai-rainbow {
    background: $bg-ai-rainbow;
  }

  // Tailwind doesn't support breaking words the way we want it to.
  // This class means we'll default to not breaking words, and just use a new line instead.
  // In the case that we have a very long word/link that extends the max-width of its container,
  // We will break that word and wrap it to the next line.
  .break-words {
    overflow-wrap: anywhere;
    word-break: break-word;
  }
}
