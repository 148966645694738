@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300italic,500,500italic,700,700italic");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400");

@tailwind utilities;
@layer utilities {
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-align-start {
    display: flex;
    align-items: flex-start;
  }

  .flex-center-y {
    display: flex;
    align-items: center;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
    word-break: break-word;
    hyphens: auto;
  }

  .inline-flex-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .two-column {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .two-column > * {
    flex-grow: 1;
    flex-basis: calc((30rem - 100%) * 999);
  }

  .svg-no-gradient svg path {
    fill: currentColor !important;
  }

  @media screen and (max-width: 640px) {
    .mobile-hidden {
      display: none;
    }
  }

  // Tailwind doesn't support breaking words the way we want it to.
  // This class means we'll default to not breaking words, and just use a new line instead.
  // In the case that we have a very long word/link that extends the max-width of its container,
  // We will break that word and wrap it to the next line.
  .break-words {
    overflow-wrap: anywhere;
    word-break: break-word;
  }

  .anchored {
    animation: noteblinker 1.5s linear;

    @keyframes noteblinker {
      0% {
        background-color: theme("colors.amber.200");
      }
    }
  }
}

// when in a drawer, we need to override the top and left styles that react-beautiful-dnd sets
// This comment: https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem#comment122557541_67075990
.drawer-container [data-rbd-draggable-id] {
  top: auto !important;
  left: auto !important;
}

body {
  /* This makes all fonts render just a bit nicer in good browsers */
  @apply antialiased;
  // Our default text size across the app should be 14px
  @apply text-sm;
}

@tailwind base;
@layer base {
  :root {
    --chart-1: 234 85 69; // ea5545
    --chart-2: 39 174 239; // 27aeef
    --chart-3: 179 61 198; // b33dc6
    --chart-4: 244 106 155; // f46a9b
    --chart-5: 239 155 32; // ef9b20
    --chart-6: 237 191 51; // edbf33
    --chart-7: 237 225 91; // ede15b
    --chart-8: 189 207 50; // bdcf32
    --chart-9: 135 188 69; // 87bc45
  }

  // Custom styling for search input clear button in WebKit browsers
  // Firefox doesn't have a clear button
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M11.7552 3.03646C12.0812 3.36244 12.0812 3.88733 11.7552 4.2133L8.9719 7.00001L11.7552 9.78673C12.0644 10.096 12.0812 10.5793 11.8051 10.9076L11.7552 10.9636C11.4292 11.2896 10.9043 11.2896 10.5783 10.9636L7.79162 8.17692L5.00491 10.9636C4.67893 11.2896 4.15405 11.2896 3.82807 10.9636C3.50209 10.6376 3.50209 10.1128 3.82807 9.78673L6.61135 7.00001L3.82807 4.2133C3.51894 3.90417 3.50209 3.42091 3.77823 3.09264L3.82807 3.03646C4.15405 2.71048 4.67893 2.71048 5.00491 3.03646L7.79162 5.82311L10.5783 3.03646C10.9043 2.71048 11.4292 2.71048 11.7552 3.03646Z' fill='%2395959D'/></svg>");
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 8px;
  }
}
