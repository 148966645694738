:global(.dark) {
  .pillOperational {
    fill: theme("colors.operational.dark.content.primary");
    background-color: theme("colors.operational.dark.content.primary");

    &.active,
    &:hover {
      fill: theme("colors.operational.dark.content.primaryHover");
      background-color: theme("colors.operational.dark.content.primaryHover");
    }
  }

  .pillNoData {
    fill: theme("colors.slate.800");
    background-color: theme("colors.slate.800");

    &.active,
    &:hover {
      fill: theme("colors.slate.700");
      background-color: theme("colors.slate.700");
    }
  }

  .pillDegradedPerformance {
    fill: theme("colors.degradedPerformance.dark.content.primary");
    background-color: theme("colors.degradedPerformance.dark.content.primary");

    &.active,
    &:hover {
      fill: theme("colors.degradedPerformance.dark.content.primaryHover");
      background-color: theme("colors.degradedPerformance.dark.content.primaryHover");
    }
  }

  .pillPartialOutage {
    fill: theme("colors.partialOutage.dark.content.primary");
    background-color: theme("colors.partialOutage.dark.content.primary");

    &.active,
    &:hover {
      fill: theme("colors.partialOutage.dark.content.primaryHover");
      background-color: theme("colors.partialOutage.dark.content.primaryHover");
    }
  }

  .pillFullOutage {
    fill: theme("colors.fullOutage.dark.content.primary");
    background-color: theme("colors.fullOutage.dark.content.primary");

    &.active,
    &:hover {
      fill: theme("colors.fullOutage.dark.content.primaryHover");
      background-color: theme("colors.fullOutage.dark.content.primaryHover");
    }
  }

  .pillUnderMaintenance {
    fill: theme("colors.maintenance.dark.content.primary");
    background-color: theme("colors.maintenance.dark.content.primary");

    &.active,
    &:hover {
      fill: theme("colors.maintenance.dark.content.primaryHover");
      background-color: theme("colors.maintenance.dark.content.primaryHover");
    }
  }
}

.pillOperational {
  fill: theme("colors.operational.content.primary");
  background-color: theme("colors.operational.content.primary");

  &.active,
  &:hover {
    fill: theme("colors.operational.content.primaryHover");
    background-color: theme("colors.operational.content.primaryHover");
  }
}

.pillNoData {
  fill: theme("colors.slate.50");
  background-color: theme("colors.slate.50");

  &.active,
  &:hover {
    fill: theme("colors.slate.100");
    background-color: theme("colors.slate.100");
  }
}

.pillDegradedPerformance {
  fill: theme("colors.degradedPerformance.content.primary");
  background-color: theme("colors.degradedPerformance.content.primary");

  &.active,
  &:hover {
    fill: theme("colors.degradedPerformance.content.primaryHover");
    background-color: theme("colors.degradedPerformance.content.primaryHover");
  }
}

.pillPartialOutage {
  fill: theme("colors.partialOutage.content.primary");
  background-color: theme("colors.partialOutage.content.primary");

  &.active,
  &:hover {
    fill: theme("colors.partialOutage.content.primaryHover");
    background-color: theme("colors.partialOutage.content.primaryHover");
  }
}

.pillFullOutage {
  fill: theme("colors.fullOutage.content.primary");
  background-color: theme("colors.fullOutage.content.primary");

  &.active,
  &:hover {
    fill: theme("colors.fullOutage.content.primaryHover");
    background-color: theme("colors.fullOutage.content.primaryHover");
  }
}

.pillUnderMaintenance {
  fill: theme("colors.maintenance.content.primary");
  background-color: theme("colors.maintenance.content.primary");

  &.active,
  &:hover {
    fill: theme("colors.maintenance.content.primaryHover");
    background-color: theme("colors.maintenance.content.primaryHover");
  }
}